
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import ApiService from "@/core/services/ApiService"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import $ from 'jquery'
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'
import UpdateVersionModal from "@/components/modals/forms/UpdateVersionModal.vue"

export default defineComponent({
  components: {
    UpdateVersionModal
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const refUpdateVersionModal = ref({} as any)

    const create_pcm_version = (item) => {
      if (refUpdateVersionModal.value != null) {
        refUpdateVersionModal.value.set_item({version: ''})
      }
    }

    const updateVersion = (item) => {
      console.log()
      store
        .dispatch(Actions.CREATE_PCM_VERSION, {version: item.version})
        .then(() => {
          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const sync = (id) => {
      store
        .dispatch(Actions.SYNC_PCM_VERSION, id)
        .then(() => {
          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const pcm_versions = ref([] as any)

    const query = () => {
      store
        .dispatch(Actions.GET_PCM_VERSIONS)
        .then(() => {
          pcm_versions.value = store.getters.currentPcmVersions
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    query()

    return {
      ApiService,
      pcm_versions,
      sync,
      create_pcm_version,
      updateVersion,
      refUpdateVersionModal
    }
  }
})
